import {
  FramedSectionElement,
  SectionCollapseButton,
} from '../styled/TableElements';
import { useEffect, useState } from 'react';

/**
 * A collapsible section of a Menu.
 * 
 * @param {string} id ID of the associated Category
 * @param {string} className Class name for elements
 * @param {object} children Child elements
 * @param {boolean} collapsed True if section should display collapsed
 * @param {function} collapseCallback Called when collapse is toggled
 * @param {object} dragTarget Targeting element information for drag'n'drop
 */
const FramedSection = ({ id, className, children, collapsed, collapseCallback, dragTarget }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  useEffect(() => {
    if (collapsed !== undefined) {
      setIsCollapsed(collapsed);
    }
  }, [collapsed]);

  const handleCollapse = (event) => {
    event.preventDefault();

    const newCollapseState = !isCollapsed;

    setIsCollapsed(newCollapseState);
    collapseCallback && collapseCallback(newCollapseState);
  };

  return (
    <>
      {dragTarget !== undefined && (
        <FramedSectionElement
          id={id}
          className={`${className} ${isCollapsed ? 'collapsed' : ''}`}
          onDragOver={dragTarget.onDragOver}
          onDragLeave={dragTarget.onDragLeave}
          onDrop={dragTarget.onDrop}
          data-id={dragTarget.id}
          data-item-list={dragTarget.itemList}
        >
          <SectionCollapseButton
            className={className}
            href=""
            onClick={handleCollapse}
            isCollapsed={isCollapsed}
          >
            &gt;
          </SectionCollapseButton>
          {children}
        </FramedSectionElement>
      )}
      {dragTarget === undefined && (
        <FramedSectionElement
          className={`${className} ${isCollapsed ? 'collapsed' : ''}`}
        >
          <SectionCollapseButton
            className={className}
            href=""
            onClick={handleCollapse}
            isCollapsed={isCollapsed}
          >
            &gt;
          </SectionCollapseButton>
          {children}
        </FramedSectionElement>
      )}
    </>
  );
};

export default FramedSection;
