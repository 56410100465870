import FramedSection from '../components/common/FramedSection';
import { SectionHead } from '../components/styled/TableElements';
import Currency, { getSymbol } from '../components/common/Currency';
import './css/template2.css';
import { useMediaQuery } from '../hooks/useMediaQuery';

const Template2 = ({ account, menu, currency }) => {
  let location = account.Location?.Formatted;

  if (account.IsMobile && account.MobileLocation === 'Inactive') {
    location = 'Currently Unavailable';
  }

  const isSmallScreen = useMediaQuery('(max-width: 480px)');

  const locationParts = location ? location.split(',') : [];
  const locationLine1 = locationParts[0]?.length ? locationParts[0] : '';
  let locationLine2 = locationParts[1]?.length ? locationParts[1] : '';
  locationLine2 += locationParts[2]?.length ? `, ${locationParts[2]}` : '';

  let headerImage = '/images/icon-chef-hat.png';

  if (menu.ImageAddress?.length > 0) {
    headerImage = menu.ImageAddress;
  } else if (account.ImageAddress?.length > 0) {
    headerImage = account.ImageAddress;
  }

  return (
    <div id="California">
      <div id="outer-frame">
        <div id="inner-frame">
          <div id="account-image">
            {account && account.Name && (
              <div className="image-frame">
                <img src={headerImage} />
              </div>
            )}
          </div>
          <div id="menu-offset">
            <div id="location-info">
              <p id="location-address">
                {locationLine1.length > 0 && <span>{locationLine1}</span>} |
                {locationLine2.length > 0 && <span>{locationLine2}</span>}
                {account.Location?.Phone.length > 1 && (
                  <span> | {account.Location.Phone}</span>
                )}
              </p>
            </div>
            <div id="menu-info">
              <p id="menu-name">{menu.Name}</p>
              {menu.Description && (
                <p id="menu-description">{menu.Description}</p>
              )}
            </div>

            <div id="categories">
              {menu.Categories?.length > 0 &&
                menu.Categories.map((category) => (
                  <section key={category.Name}>
                    <div className={'category-header'}>
                      <p>{category.Name.toUpperCase()}</p>
                    </div>
                    <div className="category-description">
                      {category.Desciption && <p>{category.Description}</p>}
                      {category.MenuItems && (
                        <div id="menu-items">
                          {category.MenuItems.map((menuItem) => (
                            <div key={menuItem.Name}>
                              <div className={'menu-item'}>
                                {menuItem.ImageAddress && (
                                  <div className="menu-item-image">
                                    <div className="image-frame">
                                      <img src={menuItem.ImageAddress} />
                                    </div>
                                  </div>
                                )}
                                <div className="menu-item-info">
                                  <p className="menu-item-name">
                                    {menuItem.Name.toUpperCase()}
                                  </p>
                                  {menuItem.Description && (
                                    <p className="menu-item-description">
                                      {menuItem.Description}
                                    </p>
                                  )}
                                  {menuItem.PlateItems?.filter(
                                    (plateItem) => plateItem.DisplayOnMenu
                                  ).length > 0 && (
                                    <p className="plate-items">
                                      Served with
                                      {menuItem.PlateItems.filter(
                                        (plateItem) => plateItem.DisplayOnMenu
                                      ).map((plateItem, idx) => {
                                        return (
                                          <span key={plateItem.Name}>
                                            {idx !== 0 ? ', ' : ' '}
                                            {idx ===
                                              menuItem.PlateItems.length - 1 &&
                                            idx !== 0
                                              ? ' and '
                                              : ''}
                                            {plateItem.Name}
                                          </span>
                                        );
                                      })}
                                    </p>
                                  )}
                                  {menuItem.OptionGroups &&
                                    menuItem.OptionGroups.map((optionGroup) => (
                                      <FramedSection
                                        key={optionGroup.Name}
                                        className="option-group"
                                      >
                                        <SectionHead>
                                          <label className="option-group-label">
                                            {optionGroup.Name.toUpperCase()}:
                                          </label>
                                        </SectionHead>
                                        {optionGroup.Description && (
                                          <p className="option-group-description">
                                            {optionGroup.Description}
                                          </p>
                                        )}
                                        {optionGroup.PlateItems && (
                                          <ul className="option-group-plate-items">
                                            {optionGroup.PlateItems.map(
                                              (plateItem) => (
                                                <li key={plateItem.Name}>
                                                  <div className="option-group-plate-item">
                                                    <div
                                                      className={`option-group-plate-item-name ${
                                                        plateItem.Description
                                                          ? 'tooltip'
                                                          : ''
                                                      }`}
                                                    >
                                                      {isSmallScreen &&
                                                        plateItem.Description && (
                                                          <div className="tool-tip-text">
                                                            {
                                                              plateItem.Description
                                                            }
                                                          </div>
                                                        )}
                                                      <p>
                                                        <i>{plateItem.Name}</i>
                                                      </p>
                                                    </div>
                                                    <div className="option-group-plate-item-up-charge">
                                                      <p>
                                                        {parseInt(
                                                          plateItem.UpCharge
                                                        ) > 0 &&
                                                          `(+ ${getSymbol(
                                                            currency
                                                          )} ${(
                                                            plateItem.UpCharge /
                                                            100
                                                          ).toFixed(2)})`}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </FramedSection>
                                    ))}
                                </div>
                                <div className="menu-item-price">
                                  <p>
                                    <Currency currency={currency} />
                                    {(menuItem.Price / 100).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </section>
                ))}
              {(!menu.Categories || menu.Categories?.length === 0) &&
                menu.MenuItems && (
                  <div id="menu-items">
                    {menu.MenuItems.map((menuItem) => (
                      <div key={menuItem.Name}>
                        <div className={'menu-item'}>
                          {menuItem.ImageAddress && (
                            <div className="menu-item-image">
                              <div className="image-frame">
                                <img src={menuItem.ImageAddress} />
                              </div>
                            </div>
                          )}
                          <div className="menu-item-info">
                            <p className="menu-item-name">
                              {menuItem.Name.toUpperCase()}
                            </p>
                            {menuItem.Description && (
                              <p className="menu-item-description">
                                {menuItem.Description}
                              </p>
                            )}
                            {menuItem.PlateItems?.filter(
                              (plateItem) => plateItem.DisplayOnMenu
                            ).length > 0 && (
                              <p className="plate-items">
                                Served with
                                {menuItem.PlateItems.filter(
                                  (plateItem) => plateItem.DisplayOnMenu
                                ).map((plateItem, idx) => {
                                  return (
                                    <span key={plateItem.Name}>
                                      {idx !== 0 ? ', ' : ' '}
                                      {idx === menuItem.PlateItems.length - 1 &&
                                      idx !== 0
                                        ? ' and '
                                        : ''}
                                      {plateItem.Name}
                                    </span>
                                  );
                                })}
                              </p>
                            )}
                            {menuItem.OptionGroups &&
                              menuItem.OptionGroups.map((optionGroup) => (
                                <FramedSection
                                  key={optionGroup.Name}
                                  className="option-group"
                                >
                                  <SectionHead>
                                    <label className="option-group-label">
                                      {optionGroup.Name.toUpperCase()}:
                                    </label>
                                  </SectionHead>
                                  {optionGroup.Description && (
                                    <p className="option-group-description">
                                      {optionGroup.Description}
                                    </p>
                                  )}
                                  {optionGroup.PlateItems && (
                                    <ul className="option-group-plate-items">
                                      {optionGroup.PlateItems.map(
                                        (plateItem) => (
                                          <li key={plateItem.Name}>
                                            <div className="option-group-plate-item">
                                              <div
                                                className={`option-group-plate-item-name ${
                                                  plateItem.Description
                                                    ? 'tooltip'
                                                    : ''
                                                }`}
                                              >
                                                {isSmallScreen &&
                                                  plateItem.Description && (
                                                    <div className="tool-tip-text">
                                                      {plateItem.Description}
                                                    </div>
                                                  )}
                                                <p>
                                                  <i>{plateItem.Name}</i>
                                                </p>
                                              </div>
                                              <div className="option-group-plate-item-up-charge">
                                                <p>
                                                  {parseInt(
                                                    plateItem.UpCharge
                                                  ) > 0 &&
                                                    `(+ ${getSymbol(
                                                      currency
                                                    )} ${(
                                                      plateItem.UpCharge / 100
                                                    ).toFixed(2)})`}
                                                </p>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </FramedSection>
                              ))}
                          </div>
                          <div className="menu-item-price">
                            <p>
                              <Currency currency={currency} />
                              {(menuItem.Price / 100).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template2;
