import {
  SubNavLink,
  SubNavLinkCurrent,
  SubNavSection,
} from '../styled/SubNavElements';
import { useLocation, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import VisitedContext from '../../store/nav-context';

const SubNav = () => {
  const { visited } = useContext(VisitedContext);
  const location = useLocation();
  const params = useParams();
  const isMenu = location.pathname.match('/menu/');
  const isMenuView = location.pathname.match('/view-menu/');

  const [navLinks, setNavLinks] = useState([]);

  useEffect(() => {
    const pagelocation = location.pathname.slice(1).split('/')[0];

    let linkChainMap = {
      menus: { label: 'Menu List', id: null },
      menu: { label: 'Menu', id: visited.menu },
      'menu-item': { label: 'Menu Item', id: visited['menu-item'] },
      'plate-item': { label: 'Plate Item', id: visited['plate-item'] },
      ingredient: { label: 'Ingredient', id: visited['ingredient'] }
    };

    let navLinks = [];

    for (var key in linkChainMap) {
      if (isMenuView && key === 'menu-item') {
        break;
      }
      if (pagelocation === key) {
        navLinks.push(
          <SubNavLinkCurrent
            key={linkChainMap[key].label}
            to={location.pathname}
          >
            {' '}
            > {linkChainMap[key].label}
          </SubNavLinkCurrent>
        );
        break;
      } else {
        const id = linkChainMap[key].id ?? '';
        navLinks.push(
          <SubNavLink key={linkChainMap[key].label} to={`/${key}/${id}`}>
            {' '}
            > {linkChainMap[key].label}
          </SubNavLink>
        );
      }
    }

    setNavLinks(navLinks);
  }, [visited, location.pathname]);

  return (
    <>
    {visited.menu && 
      <SubNavSection>
        <SubNavLink key="home" to="/">
          home
        </SubNavLink>
        {navLinks}
        {isMenu && (
          <SubNavLink to={`/view-menu/${params.id}`}>
            <div
              style={{
                display: 'inline-block',
                width: '20px',
                height: '11px',
                backgroundImage: 'url(/images/icon-eye.webp)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                marginLeft: '30px',
              }}
            />{' '}
            View Menu
          </SubNavLink>
        )}
      </SubNavSection>
    }
    </>
  );
};

export default SubNav;
