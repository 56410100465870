const ConfirmationPrompt = ({ cancelAction, continueAction }) => {
  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        zIndex: '10000',
      }}
    >
      <div
        style={{
          padding: '10px',
          color: 'white',
          backgroundColor: '#29663d',
          boxShadow: '2px 2px 4px black',
        }}
      >
        <p style={{ margin: '2px 0' }}>
          Are you sure? <a onClick={cancelAction}> Cancel</a> |
          <a onClick={continueAction}> Continue</a>
        </p>
      </div>
    </div>
  );
};

export default ConfirmationPrompt;
