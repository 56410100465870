import { createContext, useState } from 'react';

const AuthContext = createContext({
  token: null,
  setToken: () => {},
});

export const AuthContextProvider = (props) => {
  let timer;

  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    return userToken?.token;
  };

  const getCurrency = () => {
    const currency = sessionStorage.getItem('currency');

    return currency ? currency : 'Dollar';
  };

  const getSessionId = () => {
    const sid = sessionStorage.getItem('sid');

    return JSON.parse(sid);
  };

  const [token, setToken] = useState(getToken());
  const [currency, setCurrency] = useState(getCurrency());
  const [sid, setSid] = useState(getSessionId());

  const resetClearTokenTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      sessionStorage.removeItem('token');
    }, 1000 * 60 * 60);
  };

  const pingToken = () => {
    resetClearTokenTimer();
  };

  const saveToken = (userToken) => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
    timer = setTimeout(() => {});
    resetClearTokenTimer();
  };

  const saveCurrency = (currency) => {
    sessionStorage.setItem('currency', currency);
    setCurrency(currency);
  };

  const saveSid = (sid) => {
    sessionStorage.setItem('sid', JSON.stringify(sid));
    setSid(sid);
  };

  return (
    <AuthContext.Provider
      value={{
        token: token,
        setToken: saveToken,
        pingToken: pingToken,
        currency: currency,
        setCurrency: saveCurrency,
        sid: sid,
        setSid: saveSid,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
