import styled, { css } from 'styled-components';

export const HamburgerElements = styled.nav`
  width: 45px;
  background: none;
  cursor: pointer;
  margin-top: -5px;
`;

export const HamburgerAction = styled.span`
  display: inline-block;
  color: ${(theme) => (theme.color === 'white' ? 'white' : '#29663d')};
  background: none;
  font-style: normal;
  font-size: 36px;
  padding: 10px;
  margin-top: -3px;
`;

export const MenuClose = styled.span`
  display: block;
  position: absolute;
  top: 1px;
  right: 5px;
  font-size: 11px;
  font-weight: 800 
  width: 10px;
  height: 10px;
  color: white;
  cursor: pointer;
`;

export const MenuStack = styled.ul`
  position: absolute;
  ${(menu) => {
    if (menu.small) {
      return css`
        top: 5.5rem;
        right: ${(menu) => (menu.show ? '7vw' : '-50vw')};
        transition: right 0.2s;
      `;
    } else {
      return css`
        top: 132px;
        left: ${(menu) => (menu.show ? '70px' : '-300px')};
        transition: left 0.2s;
      `;
    }
  }}
  list-style-type: none;
  padding: 0 0 7px 0;
  margin: 0;
  height: ${(menu) => (menu.show ? 'auto' : '0')};
  min-width: 140px;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.9)
  );
  border-radius: 6px;
  box-shadow: 6px 6px 5px rgba(0, 0, 0, 0.5);
  z-index: 10001;
`;

export const MenuStackHead = styled.li`
  margin: 0;
  padding: 0;
  height: 18px;
  background-color: #8bc53f;
  cursor: default;
`;

export const MenuStackItem = styled.li`
  position: relative;
  background-image: url(${(menu) => menu.image});
  background-repeat: no-repeat;
  background-position: left 8px top 0px;
  font-size: 14px;
  height: 30px;
  line-height: 32px;
  margin: 0;
  &:hover {
    background-color: rgba(174, 215, 122, 0.7);
    background-position: left 8px top -32px;
  }
`;

export const MenuStackLink = styled.a`
  display: block;
  color: rgba(150, 150, 150, 1);
  padding-left: 35px;
  letter-spacing: 0.04em;
  &:hover {
    color: #29663d;
    font-weight: 600;
    letter-spacing: 0.01em;
  }
`;
