import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const SubNavSection = styled.section`
  height: 2vh;
  margin: 1vh 0;
  @media screen and (max-width: 768px) {
    height: 4vh;
  }
`;

export const SubNavLink = styled(Link)`
  font-size: 16px;
  color: #29663d;
  font-weight: 300;
  align-items: center;
  text-decoration: none;
  text-align: center;
  height: 24px;
  width: 110px;
  min-width: 110px;
  cursor: pointer;
  &:hover {
    color: black;
  }
  @media (max-width: 768px) {
    font-size: 13px;
    width: 70px;
    min-width: 70px;
    height: 16px;
  }
`;

export const SubNavLinkCurrent = styled(SubNavLink)`
  font-weight: 600;
`;
