import useInput from '../hooks/useInput';
import useApi from '../hooks/useApi';
import TextInput from '../components/form/TextInput';
import { Button } from '../components/styled/FormElements';
import Form from '../components/form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/common/Page';
import ErrorBoundary from '../components/error/ErrorBoundary';
import PasswordInput from '../components/form/PasswordInput';

const Activate = () => {
  const params = useParams();

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    inputChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: passwordReset,
  } = useInput((value) => value.trim() !== '');

  const {
    value: confirmPasswordValue,
    isValid: confirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    inputChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    reset: confirmPasswordReset,
  } = useInput((value) => value.trim() !== '');

  const formInvalid = !passwordIsValid || !confirmPasswordIsValid;

  const { error: requestError, sendRequest } = useApi();

  const navigate = useNavigate();

  const submitHandler = (event) => {
    event.preventDefault();

    if (formInvalid) {
      return false;
    }

    sendRequest(
      {
        url: '/users/password',
        method: 'PUT',
        body: {
          email: decodeURI(params.email),
          token: params.token,
          password: passwordValue,
        },
      },
      () => {
        navigate('/login');
      }
    );

    passwordReset();
    confirmPasswordReset();
  };

  const passwordsMatch =
    !passwordHasError &&
    !confirmPasswordHasError &&
    passwordValue === confirmPasswordValue;

  const confirmPasswordError = confirmPasswordHasError
    ? '"Password" is required'
    : !passwordsMatch
    ? 'Passwords do not match'
    : '';

  return (
    <ErrorBoundary>
      <Page>
        <h3>Reset Your Password</h3>

        <Form onSubmit={submitHandler} requestError={requestError}>
          <PasswordInput
            label="New Password"
            name="password"
            value={passwordValue}
            error={passwordHasError ? '"Password" is required' : ''}
            changeHandler={passwordChangeHandler}
            blurHandler={passwordBlurHandler}
          />
          <PasswordInput
            label="Confirm Password"
            name="confirmPassword"
            value={confirmPasswordValue}
            error={confirmPasswordError}
            changeHandler={confirmPasswordChangeHandler}
            blurHandler={confirmPasswordBlurHandler}
          />
          <Button primary>Submit</Button>
        </Form>
      </Page>
    </ErrorBoundary>
  );
};

export default Activate;
