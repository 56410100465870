import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { VisitedContextProvider } from './store/nav-context';
import { AuthContextProvider } from './store/auth-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
    <VisitedContextProvider>
      <App />
    </VisitedContextProvider>
  </AuthContextProvider>
);

document.addEventListener(
  'DOMContentLoaded',
  function () {
    var noscriptContainer = document.getElementById('async-stylesheets');
    if (noscriptContainer != null) {
      var container = document.createElement('div');
      container.innerHTML = noscriptContainer.textContent;
      document.body.appendChild(container);
    }
  },
  false
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
