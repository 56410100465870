import React, { forwardRef } from 'react';
import { ErrorSpan, InlineField, CheckInput } from '../styled/FormElements';
import { CheckButton } from '../styled/SearchElements';

/**
 * Displays input for quick editing of a resource's name. Handles `Enter` and `Escape` keypresses.
 */
const QuickTextInput = forwardRef(({
  error,
  framed,
  name,
  value,
  cancelEdit,
  blurHandler,
  changeHandler,
  submitHandler
}, ref) => {
  const onEnterPress = (event) => {
    if (event.code === 'Enter') {
      event.preventDefault();
      submitHandler(event);
      return;
    }

    if (event.code === 'Escape') {
      event.preventDefault();
      cancelEdit && cancelEdit(event);
    }
  }; 

  return (
    <InlineField>
      <CheckInput
        className="popup-trigger"
        framed={framed}
        id={`${name}-input-form`}
        name={name}
        type="text"
        value={value}
        ref={ref}
        onChange={changeHandler}
        onBlur={blurHandler}
        onKeyDown={onEnterPress}
        autoComplete="off"
      />
      {error && <ErrorSpan>{error}</ErrorSpan>}
      <CheckButton
        onClick={submitHandler}
        image="/images/icon-check.webp"
      />
    </InlineField>
  );
});

export default QuickTextInput;
