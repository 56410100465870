import { ErrorInfo } from '../styled/ErrorInfo';
import { FormElement } from '../styled/FormElements';

const Form = ({ onSubmit, requestError, children, noPage = false }) => {
  return (
    <FormElement onSubmit={onSubmit} noPage={noPage}>
      {requestError && <ErrorInfo>{requestError}</ErrorInfo>}
      {children}
    </FormElement>
  );
};

export default Form;
