import { createContext } from 'react';
import { useState } from 'react';

const VisitedContext = createContext({
  visited: {
    menu: null,
    'menu-item': null,
    'plate-item': null,
    ingredient: null,
  },
  setVisited: () => {},
  resetVisited: () => {}
});

export const VisitedContextProvider = (props) => {
  const getVisited = () => {
    const visited = sessionStorage.getItem('visited');
    return visited
      ? JSON.parse(visited)
      : {
          menu: null,
          'menu-item': null,
          'plate-item': null,
          ingredient: null,
        };
  };

  const [visited, setVisited] = useState(getVisited());

  const resetVisited = () => {
    sessionStorage.setItem('visited', JSON.stringify({}));
    setVisited({
      visited: {}
    });
  }
  const saveVisited = (visited) => {
    sessionStorage.setItem('visited', JSON.stringify(visited));
    setVisited(visited);
  };

  return (
    <VisitedContext.Provider
      value={{ visited: visited, setVisited: saveVisited, resetVisited }}
    >
      {props.children}
    </VisitedContext.Provider>
  );
};

export default VisitedContext;
