import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Spinner from './components/spinner/Spinner';
import Reset from './pages/Reset';
import Password from './pages/Password';
import MenuView from './pages/MenuView';
import MenuDisplay from './pages/MenuDisplay';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import IngredientList from './pages/IngredientList';
import ReactivateEmail from './pages/ReactivateEmail';
import ReactivatePhone from './pages/ReactivatePhone';

const Home = React.lazy(() => import('./pages/Home'));
const Map = React.lazy(() => import('./pages/Map'));
const About = React.lazy(() => import('./pages/About'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Activate = React.lazy(() => import('./pages/Activate'));
const HealthCheck = React.lazy(() => import('./pages/HealthCheck'));
const Login = React.lazy(() => import('./pages/Login'));
const Menu = React.lazy(() => import('./pages/Menu'));
const MenuList = React.lazy(() => import('./pages/MenuList'));
const MenuItem = React.lazy(() => import('./pages/MenuItem'));
const PlateItem = React.lazy(() => import('./pages/PlateItem'));
const Ingredient = React.lazy(() => import('./pages/Ingredient'));
const Account = React.lazy(() => import('./pages/Account'));
const Pricing = React.lazy(() => import('./pages/Pricing'));

function App() {
  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/map" element={<Map />} />
          <Route path="/account" element={<Account />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/reactivate/email" element={<ReactivateEmail />} />
          <Route path="/reactivate/phone" element={<ReactivatePhone />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/password/:email/:token" element={<Password />} />
          <Route path="/healthcheck" element={<HealthCheck />} />
          <Route path="/login" element={<Login />} />
          <Route path="/menu/:id" element={<Menu />} />
          <Route path="/menus" element={<MenuList />} />
          <Route path="/menu-item/:id" element={<MenuItem />} />
          <Route path="/plate-item/:id" element={<PlateItem />} />
          <Route path="/ingredient/:id" element={<Ingredient />} />
          <Route path="/ingredient/:id" element={<Ingredient />} />
          <Route path="/pantry" element={<IngredientList />} />
          <Route path="/view-menu/:id" element={<MenuView />} />
          <Route path="/v/:restaurant/:publicId" element={<MenuDisplay />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
