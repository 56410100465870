import { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const BaseURL = process.env.REACT_APP_API_BASE_URL;

const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const sendRequest = useCallback(
    async (requestConfig, applyData, multipart = false, displaySpinner = false) => {
      displaySpinner && setIsLoading(true);
      setError('');

      try {
        const response = await fetch(BaseURL + requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : 'GET',
          headers: requestConfig.headers ? requestConfig.headers : {},
          body:
            requestConfig.body && !multipart
              ? JSON.stringify(requestConfig.body)
              : requestConfig.body,
        });

        const data = await response.json();

        if (!response.ok) {
          if (location.pathname !== '/login' && response.status === 401) {
            navigate('/login');
            return;
          }
          if (response.status === 422) {
            let errorMessage = '';
            for (const [key, value] of Object.entries(data.error)) {
              errorMessage += `"${key}": "${value}"`;
            }
            setError(errorMessage);
          } else {
            setError(`Request failed! Server responded with: "${data.error}"`);
          }
          setIsLoading(false);
          return;
        }

        applyData && applyData(data);
      } catch (err) {
        setError(err.message || 'Something went wrong!');
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    error,
    setError,
    sendRequest,
  };
};

export default useApi;
