import React, { useContext } from 'react';
import Page from '../components/common/Page';
import ErrorBoundary from '../components/error/ErrorBoundary';
import { ActionSection, Button } from '../components/styled/FormElements';
import authContext from '../store/auth-context';
import useApi from '../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import TermsText from '../components/common/TermsText';

const Terms = () => {
  const { navigate } = useNavigate();
  const { token } = useContext(authContext);
  const { sendRequest } = useApi();

  const agreeToTermsHandler = () => {
    sendRequest(
      {
        url: `/users/agreed`,
        headers: { Authorization: `Bearer ${token}` },
        method: 'PATCH',
      },
      navigate('/menus')
    );
  };

  const closeTermsHandler = (event) => {
    event.preventDefault();
    window.close();
  };

  return (
    <ErrorBoundary>
      <Page className="terms legal">
        <TermsText />
        <ActionSection>
          {token && <Button onClick={agreeToTermsHandler}>Agree</Button>}
        </ActionSection>
      </Page>
    </ErrorBoundary>
  );
};

export default Terms;
