import React, { useContext, useEffect, useState } from 'react';
import useApi from '../hooks/useApi';
import {
  HRow,
  Row,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from '../components/styled/TableElements';
import Page from '../components/common/Page';
import {
  ActionSection,
  DeleteButton,
  EditButton,
} from '../components/styled/FormElements';
import { useNavigate } from 'react-router-dom';
import ErrorBoundary from '../components/error/ErrorBoundary';
import SubNav from '../components/navigation/SubNav';
import AddButton from '../components/form/AddButton';
import AuthContext from '../store/auth-context';
import EntityNameQuickMenu from '../components/form/EntityNameQuickMenu';
import ConfirmationPrompt from '../components/styled/ConfirmationPrompt';

const MenuList = () => {
  const { token } = useContext(AuthContext);
  const { sendRequest } = useApi();
  const navigate = useNavigate();

  const [ingredients, setIngredients] = useState(null);
  const [isDirty, setIsDirty] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const updateIngredients = (data) => {
    setIngredients(data.ingredients);
    setIsDirty(false);
  };

  const dirtyPage = () => {
    setIsDirty(true);
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    if (isDirty) {
      sendRequest(
        {
          url: '/ingredients-list',
          headers: { Authorization: `Bearer ${token}` },
        },
        updateIngredients
      );
    }
  }, [isDirty, token, navigate, sendRequest]);

  const deleteIngredientHandler = (event) => {
    setDeleteId(event.target.dataset.id);
    setIsDeleting(true);
  };

  const cancelDeleteHandler = () => {
    setDeleteId(null);
    setIsDeleting(false);
  };

  const deleteIngredient = () => {
    if (deleteId) {
      sendRequest(
        {
          url: `/ingredients/${deleteId}`,
          method: 'DELETE',
          headers: { Authorization: `Bearer ${token}` },
        },
        dirtyPage
      );
      setDeleteId(null);
      setIsDeleting(false);
    }
  };

  const addIngredientHandler = (event) => {
    sendRequest(
      {
        url: '/ingredients',
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        body: { name: 'Default Ingredient', description: '' },
      },
      dirtyPage
    );
  };

  return (
    <ErrorBoundary>
      {isDeleting && (
        <ConfirmationPrompt
          cancelAction={cancelDeleteHandler}
          continueAction={deleteIngredient}
        />
      )}
      <Page>
        <Table className="menus">
          <THead>
            <HRow>
              <Th className="itemName">Ingredient Name</Th>
              <Th className="actionCell">Edit</Th>
              <Th className="actionCell">Delete</Th>
            </HRow>
          </THead>
          <TBody>
            {ingredients &&
              ingredients.map((ingredient) => (
                <Row className="menu-items" key={ingredient.ID}>
                  <Td className="itemName">
                    <EntityNameQuickMenu
                      object={ingredient}
                      objectName="ingredient"
                      resourceName="ingredients"
                      pageRefresher={dirtyPage}
                      token={token}
                    />
                  </Td>
                  <Td className="actionCell">
                    <EditButton
                      href={`/ingredient/${ingredient.ID}`}
                      image="/images/icon-edit.webp"
                    />
                  </Td>
                  <Td className="actionCell">
                    <DeleteButton
                      image="/images/icon-trash.webp"
                      data-id={ingredient.ID}
                      onClick={deleteIngredientHandler}
                    />
                  </Td>
                </Row>
              ))}
          </TBody>
        </Table>
        <ActionSection>
          <AddButton
            image="/images/icon-add.webp"
            action={addIngredientHandler}
          >
            Add Ingredient
          </AddButton>
        </ActionSection>
      </Page>
    </ErrorBoundary>
  );
};

export default MenuList;
