import {
  AddButtonAction,
  AddButtonContainer,
  AddButtonLabel,
} from '../styled/FormElements';

const AddButton = ({ image, action, children, objectId, small = false }) => {
  return (
    <AddButtonContainer small={small}>
      <AddButtonAction
        small={small}
        onClick={action}
        image={image}
        data-object-id={objectId}
      />
      <AddButtonLabel onClick={action} data-object-id={objectId}>
        {children}
      </AddButtonLabel>
    </AddButtonContainer>
  );
};

export default AddButton;
