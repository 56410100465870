import React, { useContext, useEffect } from 'react';
import {
  MainContainer,
  PageElement,
  Footer,
  MainInfoContainer,
} from '../styled/PageElements';
import Header from '../navigation/Header';
import { BackgroundImage, NavUnderlay } from '../styled/NavbarElements';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import useApi from '../../hooks/useApi';
import AuthContext from '../../store/auth-context';
import uuid from 'react-uuid';

const Page = ({ className, children, clearPage = () => {} }) => {
  const location = useLocation();
  const isHome = location.pathname === '/';
  const isInfo =
    className && (className.includes('info') || className.includes('legal'));
  const isMobile = useMediaQuery('(max-width: 480px)');
  const { token, pingToken, sid, setSid } = useContext(AuthContext);

  const { sendRequest } = useApi();

  const trackPageView = () => {
    if (!sid) {
      const sid = uuid();
      setSid(sid);
    }

    let page = location.pathname.split('/')[1];
    page = page.length > 0 ? page : 'home';
    if (page === 'v') {
      page = 'display-menu';
    }

    sendRequest(
      {
        url: `/page-view`,
        method: 'POST',
        body: {
          page: page,
          sessionId: sid,
          knownUser: token,
          timestamp: new Date().getTime().toString(),
        },
      },
      () => {}
    );
  };

  useEffect(() => {
    trackPageView();
    pingToken();
  }, []);

  return (
    <>
      {!isHome && <NavUnderlay />}
      {!isHome && <BackgroundImage image="/images/thyme.png" />}
      <PageElement className={className} onClick={clearPage}>
        <Header />
        {!isInfo && (
          <MainContainer className={className}>{children}</MainContainer>
        )}
        {isInfo && <MainInfoContainer>{children}</MainInfoContainer>}
        <Footer>
          <p>
            &copy; {new Date().getFullYear()} All rights reserved. Sprigl LLC
          </p>
          <p>
            <a href="/terms">terms {!isMobile && '& conditions'}</a> |{' '}
            <a href="/privacy">privacy {!isMobile && 'policy'}</a>
          </p>
        </Footer>
      </PageElement>
    </>
  );
};

export default Page;
