import styled from 'styled-components';

export const SearchBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: space-between;
  align-items: center;
  width: 50vw;
  max-width: 450px;
  min-width: 300px;
  height: 46px;
  margin: auto;
  color: #808184;
  background-color: white;
  background-image: url(${(location) => location.image});
  background-repeat: no-repeat;
  background-position: left 12px top 11px;
  border-radius: 23px;
  @media screen and (max-width: 281px) {
    min-width: 250px;
  }
`;

export const SearchInput = styled.input`
  text-align: left;
  font-size: 16px;
  width: 80%;
  height: 28px;
  margin-left: 40px;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const SearchButton = styled.button`
  width: 36px;
  height: 36px;
  margin: 6px;
  border-radius: 18px;
  color: rgba(0, 0, 0, 0);
  background-color: #8bc53f;
  background-image: url(${(arrow) => arrow.image});
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  &: hover {
    background-color: #62cb31;
  }
`;

export const CheckButton = styled.button`
  position: absolute;
  top: 2px;
  right: -3px;
  width: 30px;
  height: 30px;
  margin: 6px;
  border-radius: 18px;
  color: rgba(0, 0, 0, 0);
  background-image: url(${(arrow) => arrow.image});
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  background-color: rgba(100, 100, 100, 1);
  cursor: pointer;
  &:hover {
    background-color: #8bc53f;
  }
`;
