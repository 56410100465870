import React, { useContext, useEffect } from 'react';
import {
  LogoImage,
  NavContainer,
  NavLink,
  NavLinkGreen,
  NavLinkLogo,
  NavLogoContainer,
  NavMenu,
} from '../styled/NavbarElements';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import HamburgerMenu from './HamburgerMenu';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const Header = () => {
  const ctx = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const isSignUp = location.pathname === '/signup';
  const isLogin = location.pathname === '/login';
  const isHome = location.pathname === '/';
  const isMap = location.pathname === '/map';
  const showMenusLink = isHome || /^\/v\/.*\/.*/.test(location.pathname);
  const logoImage = isHome
    ? '/images/sprigl-logo-wht.webp'
    : '/images/sprigl-logo-grn.webp';

  const hamburgerColor = isHome ? 'white' : 'green';
  const navColor = isHome ? 'dark' : 'light';

  const logoutHandler = (event) => {
    event.preventDefault();
    ctx.setToken('');
    navigate('/');
  };

  return (
    <NavContainer transparent={isHome}>
      {!ctx.token && (
        <NavMenu>
          {!isSmallScreen && <HamburgerMenu color={hamburgerColor} />}
          <NavLogoContainer>
            <NavLinkLogo to="/">
              <LogoImage alt="Sprigl Logo" src={logoImage} />
            </NavLinkLogo>
          </NavLogoContainer>
          {!isMap && (
            <NavLink to="/map" color={navColor}>
              Map
            </NavLink>
          )}
          {!isSmallScreen && isHome && (
            <NavLink color={navColor} to="/login">
              Sign In
            </NavLink>
          )}
          {!isSmallScreen && (isHome || isLogin) && (
            <NavLinkGreen to="/signup" color={navColor}>
              Sign Up
            </NavLinkGreen>
          )}
          {!isSmallScreen && isSignUp && (
            <NavLinkGreen to="/login" color={navColor}>
              Sign In
            </NavLinkGreen>
          )}
          {isSmallScreen && <HamburgerMenu color={hamburgerColor} />}
        </NavMenu>
      )}
      {ctx.token && (
        <NavMenu>
          {!isSmallScreen && <HamburgerMenu color={hamburgerColor} />}
          <NavLogoContainer>
            <NavLinkLogo to="/">
              <LogoImage src={logoImage} />
            </NavLinkLogo>
          </NavLogoContainer>
          {!isMap && (
            <NavLink to="/map" color={navColor}>
              Map
            </NavLink>
          )}
          {showMenusLink && (
            <NavLink color={navColor} to="/menus">
              Menus
            </NavLink>
          )}
          {!isSmallScreen && (
            <NavLinkGreen to="/" color={navColor} onClick={logoutHandler}>
              Log Out
            </NavLinkGreen>
          )}
          {isSmallScreen && <HamburgerMenu color={hamburgerColor} />}
        </NavMenu>
      )}
    </NavContainer>
  );
};

export default Header;
