import { Field, Label, Input, ErrorSpan } from '../styled/FormElements';

const TextInput = (props) => {
  const labelText = props.label ? props.label : props.name;

  return (
    <Field flex={props.flex}>
      <Label htmlFor={props.name}>{labelText}</Label>
      <Input
        className={props.className}
        id={props.name}
        name={props.name}
        ref={props.reference}
        type="text"
        value={props.value}
        onChange={props.changeHandler}
        onBlur={props.blurHandler}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      {props.error && <ErrorSpan>{props.error}</ErrorSpan>}
    </Field>
  );
};

export default TextInput;
