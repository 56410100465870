import useInput from '../hooks/useInput';
import useApi from '../hooks/useApi';
import TextInput from '../components/form/TextInput';
import { Button } from '../components/styled/FormElements';
import Form from '../components/form/Form';
import { useNavigate } from 'react-router-dom';
import Page from '../components/common/Page';
import ErrorBoundary from '../components/error/ErrorBoundary';

const Activate = () => {
  const {
    value: emailValue,
    isValid: emailTokenIsValid,
    hasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useInput((value) => value.trim() !== '');

  const formInvalid = !emailTokenIsValid;

  const { error: requestError, sendRequest } = useApi();

  const navigate = useNavigate();

  const submitHandler = (event) => {
    event.preventDefault();

    if (formInvalid) {
      return false;
    }

    sendRequest(
      {
        url: '/users/reset',
        method: 'PUT',
        body: { email: emailValue },
      },
      () => {
        navigate('/login');
      }
    );

    emailReset();
  };

  return (
    <ErrorBoundary>
      <Page>
        <h3>Forgot Your Password?</h3>

        <p>
          An email with instruction to reset your password will be sent to the
          (verified) email address.
        </p>
        <Form onSubmit={submitHandler} requestError={requestError}>
          <TextInput
            label="Email Address"
            name="email"
            value={emailValue}
            error={emailHasError ? '"Email" is required' : ''}
            changeHandler={emailChangeHandler}
            blurHandler={emailBlurHandler}
          />
          <Button primary>Submit</Button>
        </Form>
      </Page>
    </ErrorBoundary>
  );
};

export default Activate;
