import React, { useEffect, useState, useContext, useRef } from 'react';
import Page from '../components/common/Page';
import ErrorBoundary from '../components/error/ErrorBoundary';
import useApi from '../hooks/useApi';
import AuthContext from '../store/auth-context';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActionSection,
  Button,
  Select,
} from '../components/styled/FormElements';
import '../templates/css/template1.css';
import SubNav from '../components/navigation/SubNav';
import Template1 from '../templates/Template-1';
import Template2 from '../templates/Template-2';

const MenuView = () => {
  const { token, currency } = useContext(AuthContext);
  const { navigate } = useNavigate();
  const params = useParams();
  const { error: requestError, sendRequest } = useApi();

  const [account, setAccount] = useState({});
  const [menu, setMenu] = useState({});
  const [workingVersion, setWorkingVersion] = useState(0);
  const [showIsWorkingVersion, setShowIsWorkingVersion] = useState(true);
  const [displayedVersion, setDisplayedVersion] = useState(0);
  const [versionIds, setVersionIds] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);

  const [template, setTemplate] = useState('template1');

  const displayTemplate = (event) => {
    event.preventDefault();
    setTemplate(event.target.dataset.template);
    sendRequest(
      {
        url: `/menus/${params.id}`,
        method: 'PATCH',
        headers: { Authorization: `Bearer ${token}` },
        body: {
          template: event.target.dataset.template,
        },
      },
      (data) => {}
    );
  };

  const updateAccount = (account) => {
    console.log(account);
    setAccount(account);
  };

  const updateMenu = (menu) => {
    setMenu(menu);
    if (menu.Template.length > 0) {
      setTemplate(menu.Template);
    }
  };

  const reloadMenu = () => {
    sendRequest(
      {
        url: `/menus/${params.id}`,
        headers: { Authorization: `Bearer ${token}` },
      },
      (data) => {
        updateMenu(data.menu);
        sendRequest(
          {
            url: `/menu-version-ids/${params.id}`,
            headers: { Authorization: `Bearer ${token}` },
          },
          (data) => {
            setVersionIds(data.menuVersions);
          }
        );
      }
    );
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }

    sendRequest(
      {
        url: `/accounts`,
        headers: { Authorization: `Bearer ${token}` },
      },
      (data) => {
        updateAccount(data.account);
      }
    );

    sendRequest(
      {
        url: `/menus/${params.id}`,
        headers: { Authorization: `Bearer ${token}` },
      },
      (data) => {
        updateMenu(data.menu);
        setWorkingVersion(data.menu.CurrentVersion);
        sendRequest(
          {
            url: `/menu-version-ids/${params.id}`,
            headers: { Authorization: `Bearer ${token}` },
          },
          (data) => {
            setVersionIds(data.menuVersions);
          }
        );
      }
    );
  }, [token, params.id]);

  useEffect(() => {
    if (displayedVersion === 0) {
      setShowIsWorkingVersion(true);
      setTimeout(() => {
        setShowIsWorkingVersion(false);
      }, 3000);
    } else {
      setShowIsWorkingVersion(false);
    }
  }, [workingVersion, displayedVersion]);

  const versionSelectorHandler = (event) => {
    const versionId = parseInt(event.target.value);
    setDisplayedVersion(versionId);

    if (versionId === 0) {
      sendRequest(
        {
          url: `/admin/menus/${menu.ID}`,
          headers: { Authorization: `Bearer ${token}` },
        },
        (data) => {
          updateMenu(data.menu);
        }
      );

      return;
    }

    sendRequest(
      {
        url: `/menu-version/${params.id}/${versionId}`,
        headers: { Authorization: `Bearer ${token}` },
      },
      (data) => {
        setMenu(data.menu);
      }
    );
  };

  const publishMenuPrompt = (event) => {
    event.preventDefault();
    setShowPrompt(true);
  };

  const cancelPublish = (event) => {
    event.preventDefault();
    setShowPrompt(false);
  };

  const publishMenu = (event) => {
    event.preventDefault();

    const version =
      displayedVersion === 0 ? menu.CurrentVersion : displayedVersion;

    sendRequest(
      {
        url: `/menus/publish/${params.id}/${version}`,
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
      },
      (data) => {
        setShowPrompt(false);
        reloadMenu();
      }
    );
  };

  const deleteVersionPrompt = (event) => {
    event.preventDefault();
    setShowDeletePrompt(true);
  };

  const cancelDelete = (event) => {
    event.preventDefault();
    setShowDeletePrompt(false);
  };

  const deleteMenuVersion = (event) => {
    event.preventDefault();

    sendRequest(
      {
        url: `/menu-version/${params.id}/${displayedVersion}`,
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      },
      (data) => {
        setShowDeletePrompt(false);
        reloadMenu();
      }
    );
  };

  const toggleShowTemplateSelector = () => {
    setShowTemplateSelector(!showTemplateSelector);
  };

  const renderTemplate = (template) => {
    switch (template) {
      case 'template1':
        return <Template1 menu={menu} account={account} currency={currency} />;

      case 'template2':
        return <Template2 menu={menu} account={account} currency={currency} />;

      default:
        return '';
    }
  };

  return (
    <ErrorBoundary>
      <Page>
        <SubNav />
        <div id="publish-action">
          {versionIds?.length > 1 && (
            <Select
              value={displayedVersion}
              onChange={versionSelectorHandler}
              style={{ marginRight: '20px' }}
            >
              <option key="working-version" value={0}>
                Working Version
              </option>
              {versionIds.map((version) => (
                <option key={version} value={version}>
                  {menu.Name} v.{version}
                </option>
              ))}
            </Select>
          )}
          {displayedVersion !== 0 && (
            <Button onClick={deleteVersionPrompt}>Delete</Button>
          )}
          <Button onClick={toggleShowTemplateSelector}>Designs</Button>
          <Button onClick={publishMenuPrompt}>Publish</Button>
          <div
            className={`working-version-label ${
              showIsWorkingVersion ? 'show' : 'hide'
            }`}
          >
            <p>current working version</p>
          </div>
        </div>

        {menu && renderTemplate(template)}

        {showPrompt && (
          <div id="version-prompt">
            {requestError && <p class="error">{requestError}</p>}
            <h2>Notice:</h2>
            {menu.CurrentVersion === 0 && (
              <>
                <p>
                  Publishing will create a snapshot of this menu in it's current
                  state, and save it as a new version. It will then set it as
                  the current live version for public viewing.
                </p>
                <p>
                  You can continue to edit your menus as much as you want, but
                  we are currently limiting saved version counts to 3. Be
                  prudent.
                </p>
                {!account.Active && (
                  <p>
                    Please agree to the <a href="/terms">terms & conditions</a>{' '}
                    to proceed.
                  </p>
                )}
                {account.Active && (
                  <ActionSection>
                    <Button onClick={cancelPublish}>Cancel</Button>
                    <Button onClick={publishMenu}>Continue</Button>
                  </ActionSection>
                )}
              </>
            )}
            {menu.CurrentVersion !== 0 && (
              <>
                <p>
                  Are you sure? This will set this version as the current live
                  version for public viewing.
                </p>
                <p>This will not affect the current working version.</p>
                {account.Active && (
                  <ActionSection>
                    <Button onClick={cancelPublish}>Cancel</Button>
                    <Button onClick={publishMenu}>Continue</Button>
                  </ActionSection>
                )}
              </>
            )}
          </div>
        )}
        {showDeletePrompt && (
          <div id="version-prompt">
            {requestError && <p class="error">{requestError}</p>}
            <h2>Notice:</h2>
            <p>
              This will permanently delete the saved version. If it is currently
              published, the published version will revert to the previous
              version.
            </p>
            <p>This will not affect the current working version.</p>
            <ActionSection>
              <Button onClick={cancelDelete}>Cancel</Button>
              <Button onClick={deleteMenuVersion}>Continue</Button>
            </ActionSection>
          </div>
        )}
      </Page>
      {showTemplateSelector && (
        <div id="template-selector">
          <h2>Templates</h2>
          <div
            className={`design ${template === 'template1' ? 'selected' : ''}`}
          >
            <a onClick={displayTemplate} data-template="template1">
              Template One
            </a>
          </div>
          <div
            className={`design ${template === 'template2' ? 'selected' : ''}`}
          >
            <a onClick={displayTemplate} data-template="template2">
              Template Two
            </a>
          </div>
          <ActionSection justify="center">
            <Button onClick={toggleShowTemplateSelector}>Close</Button>
          </ActionSection>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default MenuView;
