import { useState, useCallback, useRef, useEffect } from 'react';
import useApi from '../../hooks/useApi';
import useInput from '../../hooks/useInput';
import { ContentToggle } from '../styled/ContentToggle';
import QuickTextInput from './QuickTextInput';

const EntityNameQuickMenu = ({
  parent,
  parentId,
  object,
  objectName,
  resourceName,
  checkList,
  pageRefresher,
  token,
  isEditingHandler,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const { sendRequest } = useApi();
  const originalName = object.Name;
  const itemNameRef = useRef(null);

  const defaultItemNames = [
    'Default Menu Item',
    'Default Plate Item',
    'Default Ingredient',
  ];

  const toggleEditingViews = (event) => {
    event.preventDefault();

    const show = !showForm;
    
    setShowForm(show);
    
    if (isEditingHandler) {
      isEditingHandler(show);
    }
  };

  useEffect(() => {
    if (showForm) {
      itemNameRef.current.focus();
    }
  }, [showForm])

  const {
    value: nameValue,
    setValue: setName,
    isValid: nameIsValid,
    hasError: nameHasError,
    inputChangeHandler: nameChangeHandler,
  } = useInput((value) => value.trim() !== '', object.Name);

  const updateName = useCallback(
    (data) => {
      setName(data[objectName].Name);
    },
    [setName]
  );

  const suggestMatches = (event) => {
    const { target: { value } } = event;

    if (checkList && value.length >= 2) {
      const suggestionList = checkList.filter(({ Name: name }) =>
        name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(suggestionList);
    }
    nameChangeHandler(event);
  };

  const selectSuggestion = (event) => {
    toggleEditingViews(event);
    setSuggestions([]);

    sendRequest(
      {
        url: `/${resourceName.replace(/s$/, '')}-to-${parent}/${
          event.target.dataset.id
        }/${parentId}`,
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
      },
      () => {
        if (defaultItemNames.includes(originalName)) {
          sendRequest(
            {
              url: `/${resourceName}/${object.ID}`,
              method: 'DELETE',
              headers: { Authorization: `Bearer ${token}` },
            },
            pageRefresher
          );
        } else {
          pageRefresher();
        }
      }
    );
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (nameValue === '' && originalName !== '') {
      setName(originalName);
      setShowForm(false);
    }

    if (!nameIsValid) {
      return false;
    }

    if (nameValue !== originalName) {
      sendRequest(
        {
          url: `/${resourceName}/${object.ID}`,
          method: 'PATCH',
          headers: { Authorization: `Bearer ${token}` },
          body: { name: nameValue },
        },
        (data) => {
          updateName(data);
          pageRefresher();
        }
      );
    }

    toggleEditingViews(event);
  };

  return (
    <>
      <ContentToggle show={showForm}>
        <a id={object.ID} href="" onClick={toggleEditingViews}>
          {object.Name}
        </a>
      </ContentToggle>
      <ContentToggle show={!showForm}>
        <QuickTextInput
          framed
          label="Name"
          name={`${objectName}-${object.ID}`}
          value={nameValue}
          error={nameHasError ? 'Valid "Name" is required' : ''}
          ref={itemNameRef}
          cancelEdit={toggleEditingViews}
          changeHandler={suggestMatches}
          submitHandler={submitHandler}
        />
        <ul
          className="suggestions"
          style={{
            position: 'absolute',
            top: '38px',
            left: '30px',
            listStyle: 'none',
            margin: 0,
            padding: 0,
            boxShadow: '0 -2px 1px #333',
            borderBottom: '1px solid #333',
            zIndex: 10,
          }}
        >
          {suggestions.length > 0 &&
            suggestions.map((suggestion, index) => (
              <li
                key={suggestion.ID}
                data-id={suggestion.ID}
                data-name={suggestion.Name}
                onClick={selectSuggestion}
                style={{
                  backgroundColor: 'white',
                  color: '#333',
                  borderTop: '1px solid #eee',
                  padding: '3px 10px',
                  minWidth: '200px',
                  cursor: 'pointer',
                }}
              >
                {suggestion.Name}
              </li>
            ))}
        </ul>
      </ContentToggle>
    </>
  );
};

export default EntityNameQuickMenu;
