import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavContainer = styled.header`
  position: relative;
  padding: 4vh 3vw;
  height: 96px;
  background-color: transparent;
  @media (max-width: 768px) {
    padding: 2vh 5vw;
  }
`;

export const NavUnderlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: content-box;
  padding: 4vh 3vw;
  height: 96px;
  width: 100%;
  background-color: #ffffff;
  @media (max-width: 768px) {
    padding: 2vh 5vw;
  }
`;

export const BackgroundImage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  background-position: right 0 bottom 40px;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 1200px) {
    background-size: 110vh;
  }
  @media (max-width: 768px) {
    background-size: 165vh;
    background-position: right -30vw bottom 40px;
    transform: scaleX(-1);
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  width: 100%;
`;

export const NavLogoContainer = styled.div`
  flex: 4;
  padding: 0 1rem;
  height: 100%;
`;

export const NavLinkLogo = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const NavLink = styled(Link)`
  font-size: 18px;
  color: ${(theme) => (theme.color === 'dark' ? 'white' : 'black')};
  font-weight: 700;
  align-items: center;
  text-decoration: none;
  text-align: center;
  padding: 0.35rem 0.5rem 0.5rem 0.5rem;
  height: 24px;
  width: 96px;
  min-width: 110px;
  cursor: pointer;
  &:hover {
    color: ${(theme) =>
      theme.color === 'dark'
        ? 'rgba(255, 255, 255, 0.6)'
        : 'rgba(0, 0, 0, 0.6)'};
  }
  @media (max-width: 768px) {
    font-size: 13px;
    width: 70px;
    min-width: 70px;
    height: 16px;
  }
`;

export const NavLinkGreen = styled(NavLink)`
  background: #8bc53f;
  border-radius: 25px;
  color: ${(theme) => (theme.color === 'dark' ? 'black' : 'white')};
  &:hover {
    color: rgba(0, 0, 0, 0.6);
    background: linear-gradient(rgba(119, 200, 17, 1), rgba(119, 200, 17, 0.7));
  }
`;

export const LogoImage = styled.img`
  width: 112px;
  height: 96px;
`;
