import styled, { css } from 'styled-components';

export const SmallFormElement = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  padding: 10px;
  width: 300px;
`;

export const FormElement = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  ${(props) => (props.noPage ? 'margin: 50px auto' : 'margin: 0px auto')};
  width: 100%;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const FieldContainer = styled.div`
  position: relative;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 768px) {
    max-height: 40vh;
  }
`;

export const Field = styled.div`
  position: relative;
  flex-shrink: 0;
  ${(props) => (props.flex ? `flex: ${props.flex};` : '')}
  ${(props) => (props.flex ? 'margin: 10px 5px 10px 0;' : 'margin: 10px 0;')}
  &.currency-selector {
    margin-top: 0;
  }
`;

export const SmallField = styled.div`
  position: relative;
  margin: 10px 0;
`;

export const InlineField = styled.div`
  position: relative;
  margin: 10px 0;
  height: 46px;
  display: flex;
  flex: 4;
  justify-content: center;
  align-items: center;
  margin: 0 2em 0 1em;
  @media screen and (max-width: 768px) {
    margin: 0 0.3em 0 0.3em;
  }
`;

export const InlineInput = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : 'space-between'};
  align-items: ${(props) => (props.align ? props.align : 'center')};
  gap: ${(props) => (props.space ? '10px' : '0px')};
`;

export const Label = styled.label`
  display: block;
  margin: 4px 6px;
  font-size: 14px
  font-weight: 600;
  color: #77c811;
  &.currency-selector {
    margin-top: 0;
  }
`;

export const ClickLabel = styled(Label)`
  cursor: pointer;
  width: 200px;
  color: white;
  text-align: center;
  background-color: #77c811;
`;

export const QuickLabel = styled.label`
  display: block;
  margin: 1px 4px;
  font-size: 10px
  font-weight: 600;
  color: #77c811;
`;

export const Input = styled.input`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  padding: ${(props) => (props.framed ? '0 0 0 10px' : '4px 0px 4px 10px')};
  background-color: white;
  border: ${(props) =>
    props.framed ? '1px solid rgba(190, 190, 190, 0.5)' : 'none'};
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  &.small {
    width: auto;
    max-width: 90px;
  }
  &.rounded {
    border-radius: 8px;
  }
  &.cost {
    max-width: 90px;
  }
  &.price {
    max-width: 90px;
  }
  &:disabled {
    background-color: #fbfbfb;
    &:hover {
      cursor: not-allowed;
    }
  }
  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 0 0 0 0.4rem;
    &.cost {
      max-width: 50px;
    }
    &.price {
      max-width: 50px;
    }
  }
`;

export const CheckInput = styled(Input)`
  border-radius: 0 18px 18px 0;
`;

export const TextAreaInput = styled.textarea`
  position: relative;
  width: 100%;
  padding: 4px 0px 4px 10px;
  background-color: white;
  border: none;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
`;

export const Button = styled.button`
  font-size: 18px;
  color: #ffffff;
  background: #29663d;
  border-radius: 25px;
  font-weight: 600;
  margin-right: 20px;
  align-items: center;
  text-decoration: none;
  text-align: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  height: 45px;
  width: 110px;
  min-width: 110px;
  cursor: pointer;
  border: none;
  &.active {
    color: white;
  }
  &:hover {
    color: rgba(0, 0, 0, 0.6);
    background: linear-gradient(rgba(119, 200, 17, 1), rgba(119, 200, 17, 0.7));
  }
  &:disabled {
    background: #8fb49b;
    color: #c3cbc6;
    cursor: not-allowed;
    &:hover {
      color: #c3cbc6;
      background: #8fb49b;
    }
  }
  @media (max-width: 768px) {
    font-size: 15px;
    width: 70px;
    min-width: 70px;
    height: 35px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    width: 70px;
    min-width: 70px;
    height: 35px;
  }
`;

export const ErrorSpan = styled.label`
  display: block;
  margin: 4px 14px;
  font-weight: 400;
  color: red;
`;

export const ActionSection = styled.section`
  position: relative;
  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : 'space-between'};
  margin: ${(props) => (props.small ? '10px 0 0 0' : '20px 0')};
  align-items: center;
  &.account {
    align-items: start;
  }
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  cursor: pointer;
  margin-left: -4px;
  margin-top: -4px;
  border: none;
  box-shadow: none;
  &.inline {
    margin: 0 2em 0 1em;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 21px;
    height: 21px;
    top: -3px;
    left: 0;
    border: 2px solid #e8f3d9;
    background-color: white;
  }
  &:checked:before {
    width: 25px;
    height: 25px;
    border: none;
    background-color: #29663d;
  }
  &:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 9px;
  }
`;

export const DeleteButton = styled.div`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 46px;
  background-image: url(${(button) => button.image});
  background-repeat: no-repeat;
  background-position: top 17px center;
  background-color: transparent;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 26px;
    min-width: 26px;
  }
`;

export const EditButton = styled.a`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 46px;
  background-image: url(${(button) => button.image});
  background-repeat: no-repeat;
  background-position: top 17px center;
  background-color: transparent;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 26px;
    min-width: 26px;
  }
`;

export const AddButtonAction = styled.div`
  position: relative;
  ${(menu) => {
    if (menu.small) {
      return css`
        width: 24px;
        height: 24px;
        background-size: 50%;
      `;
    } else {
      return css`
        width: 46px;
        height: 46px;
      `;
    }
  }}
  border-radius: 23px;
  background-image: url(${(button) => button.image});
  background-repeat: no-repeat;
  background-position: center;
  background-color: #8bc53f;
  cursor: pointer;
  &:hover {
    background-color: #77c811;
    box-shadow: 2px 2px 3px white;
  }
`;

export const AddButtonLabel = styled.label`
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 10px;
`;

export const AddButtonContainer = styled.div`
  display: flex;
  min-width: 150px;
  margin-right: 10px;
  height: ${(props) => (props.small ? '30px' : '60px')};
  align-items: center;
  color: #77c811;
  &:hover {
    color: #29663d;
  }
`;

export const Vellips = styled.span`
  font-size: 1.2rem;
  cursor: pointer;
`;

export const Select = styled.select`
  padding: 13px;
  background-color: #77c811;
  border: none;
  color: white;
  outline: none;
  border-radius: 4px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const QuickSelect = styled(Select)`
  position: absolute;
  top: 1rem;
  left: 3rem;
  z-index: 3;
  display: none;
  outline: none;
  &.show {
    display: inline-block;
  }
  @media (max-width: 480px) {
    top: -0.2rem;
    left: 2rem;
  }
`;

export const FileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;
