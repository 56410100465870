const Currency = ({ currency }) => {
  return <span>{getSymbol(currency)}</span>;
};

export const getSymbol = (currency) => {
  let currencySymbol = '$';

  switch (currency.toLowerCase()) {
    case 'euro':
      currencySymbol = '€';
      break;
    case 'pound':
      currencySymbol = `£`;
      break;
    case 'rupee':
      currencySymbol = '₹';
      break;
    case 'yen':
      currencySymbol = '¥';
  }

  return currencySymbol;
};

export default Currency;
