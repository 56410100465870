import useInput from '../hooks/useInput';
import useApi from '../hooks/useApi';
import TextInput from '../components/form/TextInput';
import { ActionSection, Button } from '../components/styled/FormElements';
import Form from '../components/form/Form';
import { useNavigate } from 'react-router-dom';
import Page from '../components/common/Page';
import ErrorBoundary from '../components/error/ErrorBoundary';

const ReactivateEmail = () => {
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useInput((value) => value.trim() !== '');

  const formInvalid = !emailIsValid;

  const { error: requestError, sendRequest } = useApi();

  const navigate = useNavigate();

  const submitHandler = (event) => {
    event.preventDefault();

    if (formInvalid) {
      return false;
    }

    sendRequest(
      {
        url: '/users/resend/email',
        method: 'POST',
        body: { email: emailValue },
      },
      () => {
        navigate('/activate');
      }
    );

    emailReset();
  };

  return (
    <ErrorBoundary>
      <Page>
        <h1>Resend Activation Code</h1>

        <p>
          Enter the email that you used to create your account. If the email
          matches, we will send you a new activation code. If the issue
          persists, please contact support at{' '}
          <a href="mailto:info@sprigl.com">info@sprigl.com</a>
        </p>

        <Form onSubmit={submitHandler} requestError={requestError}>
          <TextInput
            label="Email"
            name="email"
            value={emailValue}
            error={emailHasError ? '"Email" is required' : ''}
            changeHandler={emailChangeHandler}
            blurHandler={emailBlurHandler}
          />
          <ActionSection>
            <Button primary>Submit</Button>
          </ActionSection>
        </Form>
      </Page>
    </ErrorBoundary>
  );
};

export default ReactivateEmail;
