import React from 'react';
import { Field, Label, Input, ErrorSpan } from '../styled/FormElements';

const PasswordInput = (props) => {
  const labelText = props.label ? props.label : props.name;

  return (
    <Field>
      <Label htmlFor={props.name}>{labelText}:</Label>
      <Input
        className={props.className}
        id={props.name}
        name={props.name}
        type="password"
        value={props.value}
        onChange={props.changeHandler}
        onBlur={props.blurHandler}
      />
      {props.error && <ErrorSpan>{props.error}</ErrorSpan>}
    </Field>
  );
};

export default PasswordInput;
