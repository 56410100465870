import styled from 'styled-components';

export const PageElement = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  @media screen and (max-width: 480px) {
    height: 90vh;
  }
`;

export const MainContainer = styled.div`
  position: relative;
  flex: 1;
  background: none;
  width: ${(props) => (props.className == 'map' ? '95vw' : '60vw')};
  margin: 10px auto;
  overflow: auto;
  @media screen and (max-width: 1200px) {
    width: 80vw;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
  }
`;

export const MainInfoContainer = styled.div`
  position: relative;
  flex: 1;
  width: 60vw;
  margin: 10px auto;
  padding: 2rem;
  overflow: auto;
  @media screen and (max-width: 480px) {
    padding: 1rem;
    width: 93vw;
  }
`;

export const Footer = styled.footer`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 5vh;
  padding: 0 2rem;
  font-size: 13px;
  background-color: black;
  color: white;
  line-height: 5vh;
  @media screen and (max-width: 480px) {
    padding: 0.2em 1em;
  }
`;
