import { useContext, useState } from 'react';
import {
  HamburgerAction,
  HamburgerElements,
  MenuClose,
  MenuStack,
  MenuStackHead,
  MenuStackItem,
  MenuStackLink,
} from '../styled/HamburgerElements';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import VisitedContext from '../../store/nav-context';

const HamburgerMenu = ({ color }) => {
  const [showMenu, setShowMenu] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const { token, setToken } = useContext(AuthContext);
  const { resetVisited } = useContext(VisitedContext);
  const navigate = useNavigate();

  const handleHamburger = () => {
    setShowMenu(!showMenu);
  };

  const handleClose = () => {
    setShowMenu(false);
  };

  const logoutHandler = (event) => {
    event.preventDefault();
    setToken('');
    navigate('/');
  };

  const goToPantry = () => {
    resetVisited();
    navigate('/pantry');
  }

  return (
    <HamburgerElements>
      <HamburgerAction onClick={handleHamburger} color={color}>
        &#9776;
      </HamburgerAction>
      <MenuStack
        show={showMenu}
        small={isSmallScreen}
        image="/images/icon-about.webp"
      >
        <MenuStackHead>
          <MenuClose onClick={handleClose}>X</MenuClose>
        </MenuStackHead>
        {!token && (
          <MenuStackItem image="/images/icon-signin.png">
            <MenuStackLink href="/login">Sign In</MenuStackLink>
          </MenuStackItem>
        )}
        {!token && (
          <MenuStackItem image="/images/icon-signup.png">
            <MenuStackLink href="/signup">Sign Up</MenuStackLink>
          </MenuStackItem>
        )}
        {token && (
          <MenuStackItem image="/images/icon-account.png">
            <MenuStackLink href="/account">My Account</MenuStackLink>
          </MenuStackItem>
        )}
        {token && (
          <MenuStackItem image="/images/icon-pantry.png">
            <MenuStackLink href="/menus">Menu List</MenuStackLink>
          </MenuStackItem>
        )}
        {token && (
          <MenuStackItem image="/images/icon-pantry.png">
            <MenuStackLink onClick={goToPantry}>My Pantry</MenuStackLink>
          </MenuStackItem>
        )}
        <MenuStackItem image="/images/icon-about.png">
          <MenuStackLink href="/about">How it works</MenuStackLink>
        </MenuStackItem>
        <MenuStackItem image="/images/icon-pricing.png">
          <MenuStackLink href="/pricing">Pricing</MenuStackLink>
        </MenuStackItem>
        {token && isSmallScreen && (
          <MenuStackItem image="/images/icon-signout.png">
            <MenuStackLink onClick={logoutHandler}>Log Out</MenuStackLink>
          </MenuStackItem>
        )}
      </MenuStack>
    </HamburgerElements>
  );
};

export default HamburgerMenu;
