import React, { useEffect, useState } from 'react';
import Page from '../components/common/Page';
import ErrorBoundary from '../components/error/ErrorBoundary';
import useApi from '../hooks/useApi';
import { useParams } from 'react-router-dom';

import { Select } from '../components/styled/FormElements';
import Template1 from '../templates/Template-1';
import Template2 from '../templates/Template-2';

const MenuDisplay = () => {
  const params = useParams();
  const { sendRequest } = useApi();

  const [account, setAccount] = useState({});
  const [menu, setMenu] = useState(null);
  const [currency, setCurrency] = useState('Dollar');

  const updateAccount = (data) => {
    setAccount(data.account);
    setCurrency(data.account.Currency);
    if (data.account.Menus) {
      setMenu(data.account.Menus[0]);
    }
  };

  const updateMenu = (event) => {
    setMenu(account.Menus[event.target.value]);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `/accounts/${params.publicId}`,
      },
      updateAccount
    );
  }, []);

  const renderTemplate = (template) => {
    switch (template) {
      case 'template1':
        return <Template1 menu={menu} account={account} currency={currency} />;

      case 'template2':
        return <Template2 menu={menu} account={account} currency={currency} />;

      default:
        return '';
    }
  };

  return (
    <ErrorBoundary>
      <Page>
        {!menu && (
          <h3
            style={{
              fontFamily: 'Georgia',
              fontStyle: 'italic',
              margin: '5vh auto',
              textAlign: 'center',
            }}
          >
            Sorry! No active menus found for this account.
          </h3>
        )}
        {account.Menus?.length > 1 && (
          <Select name="menus" onChange={updateMenu}>
            <option value="">Select Menu</option>
            {account.Menus.map((menu, idx) => (
              <option value={idx} key={menu.PublicID}>
                {menu.Name}
              </option>
            ))}
          </Select>
        )}
        {menu && renderTemplate(menu.Template)}
      </Page>
    </ErrorBoundary>
  );
};

export default MenuDisplay;
