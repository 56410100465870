import styled from 'styled-components';

export const FramedSectionElement = styled.section`
  position: relative;
  background-color: #f8ffed;
  padding: 10px 15px 10px;
  margin-top: 20px;
  transition: max-height 0.6s;
  overflow: hidden;
  &.collapsed {
    max-height: 30px;
  }
  &.option-group {
    background-color: rgba(255, 255, 255, 0.6);
    margin-top: 0;
  }
  &.option-group.collapsed {
    max-height: 22px;
  }
`;

export const SectionCollapseButton = styled.a`
  position: absolute;
  left: 1%;
  top: 1.2%;
  font-size: 1vw;
  transform: rotate(${(props) => (props.isCollapsed ? '0' : '90')}deg);
  transition: transform 0.2s;
  &.option-group {
    top: 16px;
    color: #58595b;
  }
  @media (min-width: 1700px) {
    &.option-group {
      top: -2px;
      left: -3px
      font-size: 33px;
    }
  }
  @media (max-width: 1200px) {
    &.option-group {
      top: -3px;
      font-size: 2vw;
    }
  }
  @media (max-width: 960px) {
    &.option-group {
      top: 0;
      font-size: 2vw;
    }
  }
  @media (max-width: 480px) {
    &.option-group {
      top: 2px;
      font-size: 4vw;
    }
  }
`;

export const SectionHeadContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 3px;
`;

export const SectionLabel = styled.label`
  position: relative;
  font-size: ${(props) => (props.sm ? '14px' : '18px')};
  font-weight: 600;
  flex-shrink: 0;
  color: ${(props) => (props.dark ? '#29663d' : 'rgba(119, 200, 17, 0.4)')};
  margin-left: 1em;
  &.menu {
    margin-top: 1rem;
  }
`;

export const SectionHead = styled.h2`
  position: relative;
  display: block;
  width: 100%;
  font-size: 16px;
  text-align: left;
  flex: 4;
  margin: 0.4em 0.7em;
  &.toggle {
    margin: 0 0.7rem;
  }
  @media (max-width: 768px) {
    margin: 0.4em 0.3em;
    width: 90%;
  }
`;

export const Table = styled.table`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 100%;
  color: #29663d;
  margin-top: 0.75rem;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: 0.3rem;
  }
`;

export const THead = styled.thead`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const TBody = styled.tbody`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HRow = styled.tr`
  position: relative;
  width: 100%;
  color: #77c811;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: transparent;
`;

export const Th = styled.th`
  position: relative;
  display: block;
  text-align: left;
  padding: 0.75rem 1rem 0 1rem;
  &.itemName {
    flex: 4;
  }
  &.actionCell {
    width: 5.1vw;
    text-align: center;
  }
  @media (max-width: 480px) {
    &.actionCell {
      width: 7.6vw;
    }
    font-size: 11px;
    padding: 0 0.4rem 0 0.4rem;
  }
`;

export const Row = styled.tr`
  position: relative;
  width: 100%;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  overflow: visible;
  &:nth-child(2n) {
    background-color: #dcedc5;
  }
  &.not-displayed {
    background-color: #eeeeee;
  }
`;

export const Td = styled.td`
  position: relative;
  display: block;
  text-align: left;
  padding: 0.75rem 1rem;
  &.itemName {
    flex: 4;
    white-space: nowrap;
    overflow: visible;
  }
  &.actionCell {
    width: 5vw;
    min-width: 30px;
    text-align: center;
  }
  &.unitAmountCell {
    width: 5vw;
  }
  &.price {
    padding-right: 0.5rem;
  }
  &:first-child {
    padding-left: 1rem;
  }
  @media (max-width: 480px) {
    &.actionCell {
      width: 7.6vw;
    }
    &.unitAmountCell {
      width: 11vw;
    }
    font-size: 11px;
    padding: 0 0.4rem 0 0.4rem;
  }
`;
