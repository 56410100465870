import useApi from '../hooks/useApi';
import {
  ActionSection,
  Button,
  Field,
  Label,
} from '../components/styled/FormElements';
import Form from '../components/form/Form';
import { useNavigate } from 'react-router-dom';
import Page from '../components/common/Page';
import ErrorBoundary from '../components/error/ErrorBoundary';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useState } from 'react';

const ReactivatePhone = () => {
  const [phoneValue, phoneChangeHandler] = useState('');

  const { error: requestError, sendRequest } = useApi();

  const navigate = useNavigate();

  const submitHandler = (event) => {
    event.preventDefault();

    if (phoneValue.trim() === '') {
      return false;
    }

    sendRequest(
      {
        url: '/users/resend/phone',
        method: 'POST',
        body: { phone: phoneValue },
      },
      () => {
        navigate('/activate');
      }
    );

    phoneChangeHandler('');
  };

  return (
    <ErrorBoundary>
      <Page>
        <h1>Resend Activation Code</h1>

        <p>
          Enter the phone number that you used to create your account. If the
          number matches, we will send you a new activation code. If the issue
          persists, please contact support at{' '}
          <a href="mailto:info@sprigl.com">info@sprigl.com</a>
        </p>

        <Form onSubmit={submitHandler} requestError={requestError}>
          <Field>
            <Label htmlFor="phone">Phone Number</Label>
            <PhoneInput
              id="phone"
              placeholder="Enter phone number"
              value={phoneValue}
              onChange={phoneChangeHandler}
            />
          </Field>
          <ActionSection>
            <Button primary>Submit</Button>
          </ActionSection>
        </Form>
      </Page>
    </ErrorBoundary>
  );
};

export default ReactivatePhone;
